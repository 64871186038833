import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VBadge,{attrs:{"bordered":"","color":"light-blue lighten-3","icon":"mdi-lock","overlap":"","value":!!_vm.wdRecord.freezed && !_vm.editable}},[_c(VCard,{attrs:{"height":"350","width":"300","color":"deep-orange lighten-3","rounded":"lg"}},[_c(VToolbar,{attrs:{"height":"210","flat":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [(_vm.editable)?_c(VBtn,{staticStyle:{"z-index":"2"},attrs:{"fab":"","right":"","absolute":"","bottom":"","color":"red lighten-1","dark":""},on:{"click":_vm.deleteAvailabilityRecord}},[_c(VIcon,[_vm._v("mdi-delete")])],1):_vm._e()]},proxy:true}])},[_c(VContainer,{staticClass:"pt-12 px-0",attrs:{"fluid":""}},[_c(VSheet,{staticClass:"d-flex justify-center align-center",attrs:{"height":"210","width":"100%","rounded":"xl","color":"deep-orange lighten-5"}},[_c('div',{staticClass:"text-h3"},[_vm._v(" Reperibilità ")])])],1)],1),_c(VCardText,{staticClass:"pa-2"},[(_vm.showNotes)?_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":"","dense":""}},[_c(VCol,{staticClass:"d-flex align-start justify-start",attrs:{"cols":"7"}},[_c('div',{staticClass:"text-wrap",staticStyle:{"max-width":"200px","max-height":"50px","overflow":"hidden"}},[_vm._v(" "+_vm._s(_vm.wdRecord.note)+" ")])]),_c(VCol,{staticClass:"d-flex align-end justify-end",attrs:{"cols":"5"}},[(_vm.editable)?_c(VBtn,{staticClass:"mt-8",attrs:{"small":"","rounded":""},on:{"click":_vm.emitEditNoteEvent}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v(" mdi-text ")]),_vm._v(" Note ")],1):_vm._e()],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }