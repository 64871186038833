<template>
    <v-badge
        bordered
        color="light-blue lighten-3"
        icon="mdi-lock"
        overlap
        :value="!!wdRecord.freezed && !editable"
    >
        <v-card
            height="350"
            width="300"
            color="deep-orange lighten-3"
            rounded="lg"
            >
            <v-toolbar
                height="210"
                flat
            >
                <v-container fluid class="pt-12 px-0">

                    <v-sheet
                        height="210"
                        width="100%"
                        rounded="xl"
                        color="deep-orange lighten-5"
                        class="d-flex justify-center align-center"
                    >
                    <div class="text-h3">
                        Reperibilità
                    </div>
                    </v-sheet>
                
                
                </v-container>
            
                <template v-slot:extension>
                <v-btn
                    fab
                    right
                    absolute
                    bottom
                    style="z-index: 2;"
                    color="red lighten-1"
                    dark
                    v-if="editable"
                    @click="deleteAvailabilityRecord"
                >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
                
                </template>
                
            </v-toolbar>
            <v-card-text class="pa-2">
                <v-container fluid v-if="showNotes">
                    <v-row no-gutters dense>
                        <v-col cols="7" class="d-flex align-start justify-start">
                            <div style="max-width: 200px; max-height: 50px; overflow: hidden;" class="text-wrap">
                                
                                {{wdRecord.note}}
                            </div>
                        </v-col>
                        <v-col cols="5" class="d-flex align-end justify-end">
                            <v-btn 
                                small 
                                rounded 
                                class="mt-8"
                                v-if="editable"
                                @click="emitEditNoteEvent"
                            >
                                <v-icon left small>
                                    mdi-text
                                </v-icon>
                                Note 
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-badge>
</template>
<script>
export default {
    props: {
        wdRecord: {
            type: Object,
            required: true
        },
        // readOnly: {
        //     type: Boolean,
        //     default: false
        // },
        editable: {
            type: Boolean,
            default: true
        },
        showNotes: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        deleteAvailabilityRecord: function() {
            this.$emit('delete-availability-record');
        },
        emitEditNoteEvent: function() {
            this.$emit('edit-record-note');
        }
    },
    computed: {
        // editable: function() {
        //     return !(this.wdRecord.freezed || this.readOnly);
        // }
    }
}
</script>